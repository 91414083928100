import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Button from '../Button';

import notFoundIcon from '../../img/icons/not-found.svg';
import thanksIcon from '../../img/icons/thanks.svg';
import successIcon from '../../img/icons/success.svg';
import supermanIcon from '../../img/icons/superman.svg';
import userIcon from '../../img/icons/user.svg';

import style from './UserMessage.module.css';

const icons = {
  notFound: notFoundIcon,
  searchNotFound: notFoundIcon,
  ratingsLoadError: notFoundIcon,
  deleteReviewError: notFoundIcon,
  error: notFoundIcon,
  reviewsLoadError: notFoundIcon,
  noReviews: supermanIcon,
  signIn: userIcon,
  logInFail: notFoundIcon,
  logInSuccess: successIcon,
  logOut: successIcon,
  thanks: thanksIcon,
};

const UserMessage = ({ type, onClick, values = {}, counter }) => {
  const intl = useIntl();

  let infoSection = null;
  let isSmall = false;
  let withBackground = false;

  switch (type) {
    case 'searchNotFound': {
      isSmall = true;
      infoSection = (
        <Button
          titleKey={`main.userMessage.${type}.button`}
          onClick={onClick}
        />
      );
      break;
    }
    case 'reviewsLoadError':
    case 'ratingsLoadError':
    case 'deleteReviewError':
    case 'signIn':
    case 'noReviews': {
      isSmall = true;
      withBackground = true;
      infoSection = (
        <Button
          titleKey={`main.userMessage.${type}.button`}
          onClick={onClick}
        />
      );
      break;
    }
    case 'logInFail':
    case 'logInSuccess':
    case 'logOut': {
      infoSection = (
        <span>
          {intl.formatHTMLMessage(
            {
              id: `main.userMessage.${type}.info`,
            },
            {
              counter: <b>{counter}</b>,
            }
          )}
        </span>
      );
      break;
    }
    case 'notFound':
    case 'thanks':
    case 'error':
    default: {
      infoSection = (
        <Button titleKey={`main.userMessage.${type}.button`} linkTo="/cities" />
      );
      break;
    }
  }

  const classNames = classnames(style.container, {
    [style.small]: isSmall,
    [style.background]: withBackground,
  });

  return (
    <div className={classNames}>
      <div className={style.message}>
        <FormattedHTMLMessage
          id={`main.userMessage.${type}.message`}
          values={values}
        />
      </div>
      <img className={style.image} src={icons[type]} alt="icon" />
      {infoSection}
    </div>
  );
};

UserMessage.propTypes = {
  type: PropTypes.oneOf([
    'notFound',
    'thanks',
    'searchNotFound',
    'error',
    'logInFail',
    'logInSuccess',
    'logOut',
    'reviewsLoadError',
    'ratingsLoadError',
    'deleteReviewError',
    'noReviews',
    'signIn',
  ]),
  values: PropTypes.object,
  counter: PropTypes.number,
  onClick: PropTypes.func,
};

export default UserMessage;
